export default {
  data() {
    return {
      loading: false,
      loadingView: false,
      TableLoading: false,
      exportLoading:false,

      //Filter Menu
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    start_menu: false,
    end_menu: false,
    //Add Lost Bottle
      lost_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      loadingCustomer: false,
      lost_menu: false,
      listlostbottles: [],
      total_bottle: 0,
      total_damage_find: 0,
      total_empty: 0,
      total_full: 0,

      offset: 15,
      pagination: {},
      per_page: 12,
      selectedType: "empty",
      selectedProductType:1,
      productTypes: [],
      sumTotalBottle: 0,
       sumTotalValue: 0,
      types:[
        {
          name: "ຕຸກເປົ່າ",
          value: "empty"
        } ,
        {
          name: "ຕຸກມີນ້ຳ",
          value: "full"
        }
      ],

      addLostBottle: {},
      editLostBottle:{},
      selectedLostPlace: "factory",
      selectedLostPlaceFilter:'',
      factoryUser: [],
      selectedFactory: "",
      selectedOffender: "",
      dataSource:[],
      dataSourceId: "",
      selectedTruck: "",
      trucks: [],
      deliveryUser: [],
      type: "driver",

      //Customer List
      address: [],
      customers: [],
      selectedAddress: "",
  

      branches: [],
      selectedBranch: "",
      branchUsers: [],

      lostplaces: [
        {
          id: 1,
          value: "factory",
          name: "ໂຮງງານ",
        },
        {
          id: 2,
          value: "driver",
          name: "ລົດຂົນສົ່ງ",
        },
        {
          id: 3,
          value: "branch",
          name: "ສາຂາ",
        },
        {
          id: 4,
          value: "customer",
          name: "ລູກຄ້າ",
        },
      ],
      ////////
      server_errors: {
        barcode:"",
      },
      serverMsg:'',
      headers: [
        {
          text: "ຜະລິດຕະພັນ",
          align: "start",
          value: "bottle_type",
        },
        { text: "ສະຖານທີເສຍ", value: "factory" },
        { text: "ຈຳນວນຕຸກ", value: "amount" },
        { text: "ຄ່າເສຍຫາຍ", value: "damage_fine" },
        { text: "ໝາຍເຫດ", value: "reason" },
        { text: "ຜູ້ຮັບຜິດຊອບ", value: "store_type" },
        { text: "ປະເພດຕຸກ", value: "type" },
        { text: "ວັນທີເສຍ", value: "damage_date" },
        { text: "ວັນທີອັບເດດ", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false,align: "center", },
      ],
      userRule: [(v) => !!v || "User is required"],
      lostPlaceRule: [(v) => !!v || "ກາລຸນາປ້ອນບ່ອນເສຍຫາຍ"],
      dataSourceRule: [(v) => !!v || "ກາລຸນາເລືອກຂໍ້ມູນກ່ອນ"],
      productTypeRule: [(v) => !!v || "ກາລຸນາເລືອກຂໍ້ມູນກ່ອນ"],
      typeRule: [(v) => !!v || "ກາລຸນາເລືອກຂໍ້ມູນກ່ອນ"],
      ruleDistrict: [(v) => !!v || "District is required"],
      ruleBranch: [(v) => !!v || "Branch is required"],
      ruleTruck: [(v) => !!v || "Truck is required"],
      ruleCustomer: [(v) => !!v || "Customer is required"],
      payforfineRules: [(v) => !!v || "ການລຸນາປ້ອນຄ່າເສຍຫາຍ"],

      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "ເກີດຂໍ້ຜິດພາດບາງຢ່າງ",
      },
    };
  },
  methods: {
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
      this.fetchFactoryUser();
      this.fetchDriver();
      this.listDeliveryUser();
      this.listBranch();
      this.listBranchUser();
      this.fetchAddress();
    },
    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },
    SubmitItem() {
      this.addLostBottle.offender_id = this.selectedOffender;
      this.addLostBottle.lost_date = this.lost_date;
      this.addLostBottle.lost_place = this.selectedLostPlace;
      this.loading = true;
      this.$admin
        .post("add/lost/bottles", this.addLostBottle)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              this.CloseModalAdd();
              this.addLostBottle = {};
              this.fetchLostBottle();
              this.reset();
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch((error) => {
          this.loading = false;
          // this.$store.commit("Toast_State", this.toast_error);
          if (error.response.status == 422) {
            var obj = error.response.data.errors;
            var msg = error.response.data.msg;
            this.serverMsg = msg;
            this.$store.commit("Toast_State", this.toast_error);
            for (let [key, addLostBottle] of Object.entries(obj)) {
              this.server_errors[key] = addLostBottle[0];
            }
          }
        });
    },

    CloseModalAdd() {
      this.customer = {};
      this.reset();
      this.$store.commit("modalAdd_State", false);
    },


    OpenModalEdit(item) {
      (item);
      this.editLostBottle = item;
      this.$store.commit("modalEdit_State", true);
    },

    UpdateItem() {
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin
          .put("enter-damage-fine/" + this.editLostBottle.id, {"damage_fine":this.editLostBottle.damage_fine})
          .then((res) => {
            if (res.status == 200) {
                this.loading = false;
                this.CloseModalEdit();
                this.editLostBottle = {};
                this.reset();
                this.$store.commit("Toast_State", this.toast);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            if (error.response.status == 422) {
              var obj = error.response.data.errors;
              var msg = error.response.data.msg;
              this.serverMsg = msg;
              this.$store.commit("Toast_State", this.toast_error);
              for (let [key, addLostBottle] of Object.entries(obj)) {
                this.server_errors[key] = addLostBottle[0];
              }
            }
          });
      }
    },

    CloseModalEdit() {
      this.editLostBottle = {};
      this.reset();
      this.$store.commit("modalEdit_State", false);
    },

    FilterVillages(districtId) {
      const result_checking = this.filterVillages.filter((item) => {
        return item.district_id == districtId;
      });
      this.listVillages = result_checking;
      this.selectedVillage = { ...this.listVillages[0] };
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.customerID = id;
      this.$store.commit("modalDelete_State", true);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.$admin
        .delete("delete-customer/" + this.customerID)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
        });
    },
    reset() {
      this.$refs.form.reset();
    },

    CloseAddVillage() {
      this.$store.commit("modalAdd2_State", false);
    },
    viewInvoice(value) {
      this.loadingView = true;
      this.customerDetail = value;
      this.$store.commit("modalView_State", true);
    },
    closeModalView() {
      this.loadingView = false;
      this.$store.commit("modalView_State", false);
    },

    fetchData(){
      if (this.$refs.form.validate() == true) {
        this.fetchLostBottle();
      }
    },
    // Fetch Data
    fetchLostBottle() {
      this.TableLoading = true;
      this.$admin
        .get("/report-damage-bottle", {
          params: {
            store_type: this.selectedLostPlaceFilter,
            store_type_id: this.dataSourceId,
            start_date: this.start_date,
            end_date: this.end_date,
            type_id: this.selectedProductType,
            type: this.selectedType,
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.listlostbottles = res.data.data.data;
            this.total_bottle = res.data.total_bottle;
            this.total_damage_find = res.data.total_damage_find;
            this.total_empty = res.data.total_empty;
            this.total_full = res.data.total_full;
            this.pagination = res.data.data;
            this.TableLoading = false;
            this.start_menu = false;
            this.end_menu = false;
          }

        })
        .catch(() => {
          this.TableLoading = false;
          this.start_menu = false;
          this.end_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    getProductType() {
      this.$axios.get('list-bottle-types').then(res => {
        this.productTypes = res.data.bottle_types;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },

    fetchFactoryUser() {
      this.$admin
        .get("list-factory-user")
        .then((res) => {
          setTimeout(() => {
            this.factoryUser = res.data.factoryUsers;
          }, 100);
        })
        .catch(() => {});
    },
    fetchDriver() {
      this.$admin
        .get("list-water-truck")
        .then((res) => {
          setTimeout(() => {
            this.trucks = res.data.trucks;
            this.listDeliveryUser();
          }, 100);
        })
        .catch(() => {});
    },
    listDeliveryUser() {
      this.$axios
        .get("filter-users", {
          params: {
            type: this.type,
            truckId: this.selectedTruck,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.deliveryUser = res.data.users;
          }, 100);
        })
        .catch(() => {});
    },
    listBranch() {
      this.$axios
        .get("list-branches")
        .then((res) => {
          setTimeout(() => {
            this.branches = res.data.listBranches;
            this.listBranchUser();
          }, 100);
        })
        .catch(() => {});
    },
    listBranchUser() {
      this.$axios
        .get("filter-users", {
          params: {
            type: "branch",
            branchId: this.selectedBranch,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.branchUsers = res.data.users;
          }, 100);
        })
        .catch(() => {});
    },
    fetchAddress() {
      this.$axios
        .get("list-address")
        .then((res) => {
          setTimeout(() => {
            this.address = res.data.districts;
            this.listCustomer();
          }, 100);
        })
        .catch(() => {});
    },
    listCustomer() {
      if(!this.selectedAddress == ''){
        this.loadingCustomer = true;
        this.$axios
          .get("filter-customers/" + this.selectedAddress)
          .then((res) => {
            setTimeout(() => {
              this.customers = res.data.customers;
              this.loadingCustomer = false;
            }, 100);
          })
          .catch(() => {
            this.loadingCustomer = false;
          });
      }
      },
      getstLostPlace(value){
        if(value == 'factory') return 'ໂຮງງານ'
        else if(value == 'truck') return 'ລົດຂົນສົ່ງ'
        else if(value == 'branch') return 'ສາຂາ'
        else if (value == 'customer') return 'ລູກຄ້າ'
        else return;

      },

    listSaleDataSources() {
      if (this.selectedLostPlaceFilter == 'branch') {
        this.$admin.get('list-branch').then(res => {
          this.dataSource = res.data.branches;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      } else if (this.selectedLostPlaceFilter == 'driver') {
        let originalData = [];
        this.$axios
            .get("list-water-truck")
            .then((res) => {
              originalData = res.data.trucks;
              const newData = originalData.map(item => {
                const newItem = {...item, name: item.license_plate};
                delete newItem.license_plate; // Remove the old key
                return newItem;
              });
              this.dataSource = newData;
              this.dataSource.unshift(this.defaultValue);
            })
            .catch(() => {
              this.$store.commit("Toast_State", this.toast_error);
            });

      } else if (this.selectedLostPlaceFilter == 'factory') {
        this.$admin.get('list-factories').then(res => {
          this.dataSource = res.data.data.data;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      }else if (this.selectedLostPlaceFilter == 'warehouse') {
        this.$axios.get('list-factory-warehouses').then(res => {
          this.dataSource = res.data.data;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      }
    },

    fetchExportData(){
      if (this.$refs.form.validate() == true) {
        this.exportData();
      }
    },
    exportData() {
      this.exportLoading = true;
      this.$admin
          .get(
              "export-damage-bottle",
              {
                params: {
                  store_type: this.selectedLostPlaceFilter,
                  store_type_id: this.dataSourceId,
                  start_date: this.start_date,
                  end_date: this.end_date,
                  type_id: this.selectedProductType,
                  type: this.selectedType,
                  page: this.pagination.current_page,
                  per_page: this.per_page,
                },
                responseType: "blob"
              },
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute("download", `Report-Damage-Bottle.xlsx`);
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;

            }, 100);
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$store.commit("Toast_State", this.toast_error);
            }
            this.exportLoading = false;
          });
    },
  },

  watch: {
    selectedFactoryUser: function () {
      this.fetchFactoryUser();
    },
    selectedTruck: function () {
      this.listDeliveryUser();
    },
    selectedBranch: function () {
      this.listBranchUser();
    },
    selectedAddress: function () {
      this.listCustomer();
    },
    selectedLostPlaceFilter: function () {
      this.listSaleDataSources();
    },
  },

  created() {
    this.getProductType();
    // this.fetchLostBottle();
  },
};
